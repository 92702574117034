import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron02,
  CBtnList,
  CBreadCrumb,
} from '../../components/_index';
import '../../assets/_sass/page/privacy.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron02
        size="small"
        data={{
          title: {
            main: <>規約・約款</>,
            sub: <></>,
          },
        }}
      ></CJumbotron02>

      <div className="p_privacy">
        <section className="u_mtMedium">
          <LWrap>
            <CBtnList
              data={[
                {
                  label: '宿泊約款',
                  link: {
                    href: '/assets/files/pdf/terms-for-accommodation.pdf',
                    blank: true,
                  },
                  color: 'borderBlack',
                  icon: 'document',
                },
                {
                  label: 'ご宴会・催事規約',
                  link: {
                    href: '/assets/files/pdf/terms-for-banquet.pdf',
                    blank: true,
                  },
                  color: 'borderBlack',
                  icon: 'document',
                },
                {
                  label: 'ご結婚披露宴規約',
                  link: {
                    href: '/assets/files/pdf/terms-for-wedding.pdf',
                    blank: true,
                  },
                  color: 'borderBlack',
                  icon: 'document',
                },
                {
                  label: 'サイト利用規約',
                  link: {
                    href: '/privacy/site/',
                  },
                  color: 'borderBlack',
                },
                {
                  label: 'プライバシーポリシー',
                  link: {
                    href: 'https://www.royalparkhotels.co.jp/privacy/',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
                {
                  label: 'ソーシャルメディアポリシー',
                  link: {
                    href: 'https://www.royalparkhotels.co.jp/social/',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
                {
                  label: '特定商取引に関する法律に基づく表示',
                  link: {
                    href: 'https://www.royalparkhotels.co.jp/transaction/',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
              ]}
            />
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
